import DaySwitch from '../../DaySwitch';
import Divider from '../../Divider';
import NumField from '../../NumField';
import Radio from '../../Radio';
import SimpleTable from '../../SimpeTable';
import { addDays } from 'date-fns/fp';
import { CapsuleTabContentBody, CapsuleTabContentHeader, ContentRow } from '../CapsuleComponents';
import DropDown from '../../DropDown';
import Button from '../../Button';
import Icon from '../../Icon';
import AddRemoveButton from '../../AddRemoveButton';
import { AvailabilityType } from '../../../semshared/availability/availability';
import { IFormState } from '../../../semshared/utils/lister';

interface MeetingRoomsSectionProps {
  formState: IFormState
  setFormState: any;
  meetingRoomsDay: any;
  setMeetingRoomsDay: any;
  shadowRoot: any;
  availabilityChecker: AvailabilityType | null;
  setSimpleMode: (simpleMode: boolean) => void;
  simpleMode: boolean;
}

const MeetingRoomsSectionPopup = ({ formState, setFormState, meetingRoomsDay, setMeetingRoomsDay, shadowRoot, availabilityChecker, simpleMode, setSimpleMode }: MeetingRoomsSectionProps) => {

  const simple = () => {
    return <>
      <CapsuleTabContentHeader>
        Bitte geben Sie die Seminarraumdetails an.
      </CapsuleTabContentHeader>
      <CapsuleTabContentBody>
        <SimpleTable
          verticalHeader={false}
          centeredHeader={true}
          header={['', 'Dauer', 'Bestuhlung', '']}
          data={formState.days[meetingRoomsDay].occupancy.map((m, index) => [
            <strong>{'Raum ' + (index + 1)}</strong>,
            <DropDown
              popupPosition="top"
              shadowRoot={shadowRoot}
              value={formState.days[meetingRoomsDay].occupancy[index]}
              onChange={value =>
                setFormState({
                  ...formState,
                  days: formState.days.map((d, i) => {
                    return {
                      ...d,
                      occupancy: d.occupancy.map((s, j) => j === index ? value : s)
                    };
                  }),
                })
              }
              options={[
                { value: 'FULLDAY', label: 'Ganztägig' },
                { value: 'MORNING', label: 'Vormittag' },
                { value: 'AFTERNOON', label: 'Nachmittag' },
              ]}
            />,
            <DropDown
              popupPosition="top"
              shadowRoot={shadowRoot}
              value={formState.days[meetingRoomsDay].seating[index]}
              onChange={value =>
                setFormState({
                  ...formState,
                  days: formState.days.map((d, i) => {
                    return {
                      ...d,
                      seating: d.seating.map((s, j) => j === index ? value : s)
                    };
                  }),
                })
              }
              options={[
                {
                  value: 'UFORM',
                  label: 'U-Form',
                  icon: <img src={`${process.env.REACT_APP_WIDGET_URL || ''}/assets/icons/U-form.png`} />,
                },
                {
                  value: 'THEATER',
                  label: 'Theater',
                  icon: <img src={`${process.env.REACT_APP_WIDGET_URL || ''}/assets/icons/theater.png`} />,
                },
                {
                  value: 'PARLAMENT',
                  label: 'Parlament',
                  icon: <img src={`${process.env.REACT_APP_WIDGET_URL || ''}/assets/icons/parlament.png`} />,
                },
                {
                  value: 'CIRCLE',
                  label: 'Kreis',
                  icon: <img src={`${process.env.REACT_APP_WIDGET_URL || ''}/assets/icons/kreis.png`} />,
                },
                {
                  value: 'BANKETT',
                  label: 'Bankett',
                  icon: <img src={`${process.env.REACT_APP_WIDGET_URL || ''}/assets/icons/bankett.png`} />,
                },
                {
                  value: 'COCKTAIL',
                  label: 'Cocktail',
                  icon: <img src={`${process.env.REACT_APP_WIDGET_URL || ''}/assets/icons/cocktail.png`} />,
                },
                {
                  value: 'BLOCK',
                  label: 'Block',
                  icon: <img src={`${process.env.REACT_APP_WIDGET_URL || ''}/assets/icons/block.png`} />,
                },
              ]}
            />,
            <AddRemoveButton
              iconOnly={true}
              onClick={() => {
                const newDays = formState.days[meetingRoomsDay];
                newDays.occupancy.splice(index, 1);
                newDays.seating.splice(index, 1);

                setFormState({
                  ...formState,
                  days: formState.days.map((d, i) => {
                    return {
                      ...d,
                      seating: [...newDays.seating],
                      occupancy: [...newDays.occupancy],
                    };
                  }),
                });
              }}
            >
              -
            </AddRemoveButton>,
          ])}
        />
        <br />
        {<AddRemoveButton
          iconOnly={false}
          onClick={() => {
            const newDays = formState.days[meetingRoomsDay];
            newDays.occupancy.push('FULLDAY')
            newDays.seating.push('UFORM')

            setFormState({
              ...formState,
              days: formState.days.map((d, i) => {
                return {
                  ...d,
                  seating: [...newDays.seating],
                  occupancy: [...newDays.occupancy],
                };
              }),
            });
          }}
        >
          + Seminarraum hinzufügen
        </AddRemoveButton>}
        <Divider />
        <div style={{
          color: '#AC2A6E',
          fontSize: 14,
          fontWeight: 'bold',
          cursor: 'pointer',
        }} onClick={() => setSimpleMode(!simpleMode)}>{simpleMode ? 'Zur erweiterten Ansicht' : 'Zur einfachen Ansicht'}</div>
      </CapsuleTabContentBody>
    </>
  }

  const advanced = () => {
    return <>
      <CapsuleTabContentHeader>
        <ContentRow>
          {false && (
            <>
              <div
                style={{
                  display: 'inline-block',
                  marginRight: 10,
                  width: 120,
                  fontFamily: 'Roboto, sans-serif',
                }}
              >
                Seminarräume
              </div>
              <NumField
                value={formState.meetingRooms}
                onChange={value =>
                  setFormState({
                    ...formState,
                    meetingRooms: value,
                  })
                }
              />
              <Divider />
            </>
          )}
          <DaySwitch
            value={meetingRoomsDay}
            max={formState.days.length - 1}
            onChange={v => setMeetingRoomsDay(v)}
            date={addDays(meetingRoomsDay, formState.start)}
          />
        </ContentRow>
      </CapsuleTabContentHeader>
      <CapsuleTabContentBody>
        <SimpleTable
          verticalHeader={false}
          centeredHeader={true}
          header={['', 'Dauer', 'Bestuhlung', '']}
          data={formState.days[meetingRoomsDay].occupancy.map((m, index) => [
            <strong>{'Raum ' + (index + 1)}</strong>,
            <DropDown
              popupPosition="top"
              shadowRoot={shadowRoot}
              value={formState.days[meetingRoomsDay].occupancy[index]}
              onChange={value =>
                setFormState({
                  ...formState,
                  days: formState.days.map((d, i) => {
                    if (i === meetingRoomsDay) {
                      return {
                        ...d,
                        occupancy: d.occupancy.map((s, j) => {
                          if (j === index) {
                            return value;
                          }
                          return s;
                        }),
                      };
                    }
                    return d;
                  }),
                })
              }
              options={[
                { value: 'FULLDAY', label: 'Ganztägig' },
                { value: 'MORNING', label: 'Vormittag' },
                { value: 'AFTERNOON', label: 'Nachmittag' },
              ]}
            />,
            <DropDown
              popupPosition="top"
              shadowRoot={shadowRoot}
              value={formState.days[meetingRoomsDay].seating[index]}
              onChange={value =>
                setFormState({
                  ...formState,
                  days: formState.days.map((d, i) => {
                    if (i === meetingRoomsDay) {
                      return {
                        ...d,
                        seating: d.seating.map((s, j) => {
                          if (j === index) {
                            return value;
                          }
                          return s;
                        }),
                      };
                    }
                    return d;
                  }),
                })
              }
              options={[
                {
                  value: 'UFORM',
                  label: 'U-Form',
                  icon: <img src={`${process.env.REACT_APP_WIDGET_URL || ''}/assets/icons/U-form.png`} />,
                },
                {
                  value: 'THEATER',
                  label: 'Theater',
                  icon: <img src={`${process.env.REACT_APP_WIDGET_URL || ''}/assets/icons/theater.png`} />,
                },
                {
                  value: 'PARLAMENT',
                  label: 'Parlament',
                  icon: <img src={`${process.env.REACT_APP_WIDGET_URL || ''}/assets/icons/parlament.png`} />,
                },
                {
                  value: 'CIRCLE',
                  label: 'Kreis',
                  icon: <img src={`${process.env.REACT_APP_WIDGET_URL || ''}/assets/icons/kreis.png`} />,
                },
                {
                  value: 'BANKETT',
                  label: 'Bankett',
                  icon: <img src={`${process.env.REACT_APP_WIDGET_URL || ''}/assets/icons/bankett.png`} />,
                },
                {
                  value: 'COCKTAIL',
                  label: 'Cocktail',
                  icon: <img src={`${process.env.REACT_APP_WIDGET_URL || ''}/assets/icons/cocktail.png`} />,
                },
                {
                  value: 'BLOCK',
                  label: 'Block',
                  icon: <img src={`${process.env.REACT_APP_WIDGET_URL || ''}/assets/icons/block.png`} />,
                },
              ]}
            />,
            <AddRemoveButton
              iconOnly={true}
              onClick={() => {
                const newDays = formState.days[meetingRoomsDay];
                newDays.occupancy.splice(index, 1);
                newDays.seating.splice(index, 1);
                setFormState({
                  ...formState,
                  days: formState.days.map((d, i) => {
                    if (i === meetingRoomsDay) {
                      return newDays;
                    }
                    return d;
                  }),
                });
              }}
            >
              -
            </AddRemoveButton>,
          ])}
        />
        <br />
        {<AddRemoveButton
          iconOnly={false}
          onClick={() => {
            const newDays = formState.days[meetingRoomsDay];
            newDays.occupancy.push('FULLDAY');
            newDays.seating.push('UFORM');
            setFormState({
              ...formState,
              days: formState.days.map((d, i) => {
                if (i === meetingRoomsDay) {
                  return newDays;
                }
                return d;
              }),
            });
          }}
        >
          + Seminarraum hinzufügen
        </AddRemoveButton>}
        <Divider />
        <div style={{
          color: '#AC2A6E',
          fontSize: 14,
          fontWeight: 'bold',
          cursor: 'pointer',
        }} onClick={() => setSimpleMode(!simpleMode)}>{simpleMode ? 'Zur erweiterten Ansicht' : 'Zur einfachen Ansicht'}</div>
      </CapsuleTabContentBody>
    </>
  }

  return <>
    {simpleMode ? simple() : advanced()}
  </>
}

export default MeetingRoomsSectionPopup;
