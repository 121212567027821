import React from 'react';
import Divider from '../../Divider';
import NumField from '../../NumField';
import { CapsuleTabContentBody, CapsuleTabContentHeader } from '../CapsuleComponents';
import { addDays, format } from 'date-fns/fp';
import { IFormState } from '../../../semshared/utils/lister';

interface RoomSectionProps {
  formState: IFormState
  setFormState: (formState: IFormState) => void;
  setSimpleMode: (simpleMode: boolean) => void;
  simpleMode: boolean;
}

const RoomsSection = ({ formState, setFormState, setSimpleMode, simpleMode }: RoomSectionProps) => {

  const simple = () => {
    return <>
    <CapsuleTabContentHeader>Bitte geben Sie die Anzahl der Zimmer an.</CapsuleTabContentHeader>
    <CapsuleTabContentBody>
      {!formState.start && <div></div>}
      <div
        style={{
          display: 'flex',
          fontFamily: 'Roboto, sans-serif',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            minWidth: 50,
            paddingRight: 40,
            textAlign: 'left',
          }}
        >
          <>
            <strong>Voranreisetag</strong>
            <div>{format('dd.MM.yyyy', addDays(-1, formState.start || new Date()))}</div>
          </>
        </div>
        <div
          style={{
            paddingRight: 0,
            alignSelf: 'center',
          }}
        >
          <NumField
            value={formState.prevdayGuests}
            onChange={value =>
              setFormState({
                ...formState,
                prevdayGuests: value,
              })
            }
          />
        </div>
      </div>
      <Divider />
      {formState.start &&
      <div
      style={{
        display: 'flex',
        fontFamily: 'Roboto, sans-serif',
        justifyContent: 'space-between',
      }}
    >
      <div
        style={{
          minWidth: 50,
          paddingRight: 40,
          textAlign: 'left',
        }}
      >
        <>
          <strong>Restliche Tage</strong>
        </>
      </div>
      <div
        style={{
          paddingRight: 0,
          alignSelf: 'center',
        }}
      >
          <React.Fragment>
                <NumField
                  value={formState.days[0].overnightGuests}
                  onChange={value => {
                    setFormState({
                      ...formState,
                      days: formState.days.map((day, i) => {
                          return {
                            ...day,
                            overnightGuests: value,
                          };
                      }),
                    });
                  }}
                />
          </React.Fragment>
          </div>
          </div>}
          <Divider />
          <div style={{
        color: '#AC2A6E',
        fontSize: 14,
        fontWeight:'bold',
        cursor: 'pointer',
      }} onClick={() => setSimpleMode(!simpleMode)}>{simpleMode ? 'Zur erweiterten Ansicht' : 'Zur einfachen Ansicht'}</div>
    </CapsuleTabContentBody>
  </>
  }

  const advanced = () => {
    return <>
    <CapsuleTabContentHeader>Bitte geben Sie die Anzahl der Zimmer pro Tag an.</CapsuleTabContentHeader>
    <CapsuleTabContentBody>
      {!formState.start && <div></div>}
      <div
        style={{
          display: 'flex',
          fontFamily: 'Roboto, sans-serif',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            minWidth: 50,
            paddingRight: 40,
            textAlign: 'left',
          }}
        >
          <>
            <strong>Voranreisetag</strong>
            <div>{format('dd.MM.yyyy', addDays(-1, formState.start || new Date()))}</div>
          </>
        </div>
        <div
          style={{
            paddingRight: 0,
            alignSelf: 'center',
          }}
        >
          <NumField
            value={formState.prevdayGuests}
            onChange={value =>
              setFormState({
                ...formState,
                prevdayGuests: value,
              })
            }
          />
        </div>
      </div>
      <Divider />
      {formState.start &&
        [...Array(formState.days.length)].map((_, index) => (
          <React.Fragment key={index}>
            <div
              style={{
                display: 'flex',
                fontFamily: 'Roboto, sans-serif',
                justifyContent: 'space-between',
              }}
            >
              <div
                style={{
                  minWidth: 50,
                  paddingRight: 40,
                  textAlign: 'left',
                }}
              >
                <>
                  <strong>{formState.days.length - 1 === index ? 'Verlängerungsnacht' : `Tag ${index + 1}`}</strong>
                  <div>{format('dd.MM.yyyy', addDays(index, formState.start || new Date()))}</div>
                </>
              </div>
              <div
                style={{
                  paddingRight: 0,
                  alignSelf: 'center',
                }}
              >
                <NumField
                  value={formState.days[index].overnightGuests}
                  onChange={value => {
                    setFormState({
                      ...formState,
                      days: formState.days.map((day, i) => {
                        if (index === i) {
                          return {
                            ...day,
                            overnightGuests: value,
                          };
                        } else {
                          return day;
                        }
                      }),
                    });
                  }}
                />
              </div>
            </div>
            <Divider />
          </React.Fragment>
        ))}
        <div style={{
        color: '#AC2A6E',
        fontSize: 14,
        fontWeight:'bold',
        cursor: 'pointer',
      }} onClick={() => setSimpleMode(!simpleMode)}>{simpleMode ? 'Zur erweiterten Ansicht' : 'Zur einfachen Ansicht'}</div>
    </CapsuleTabContentBody>
  </>
  }

  return <>
  {simpleMode ? simple() : advanced()}
    </>
}

export default RoomsSection;
